import {Component, OnInit} from '@angular/core'
import {IParticlesProps} from 'ng-particles'
import {ParticlesUtil} from '../../Utils/ParticlesUtil'
import {SummonerApiService} from '../../services/summoner/summonerApi.service'
import {BrowserUtil} from '../../Utils/BrowserUtil'
import {GameDataService} from '../../services/game-data/game-data.service'
import {Router} from '@angular/router'
import { Engine } from 'tsparticles-engine'
import { loadFull } from 'tsparticles'

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.less'],
  providers: [SummonerApiService]
})
export class HomePageComponent implements OnInit {
  public particlesOptions!: IParticlesProps
  public id = 'tsparticles'
  isWallpaperLoaded = false

  constructor(
      private summonerApiService: SummonerApiService,
      private router: Router,
      private gameDataService: GameDataService
  ) {
  }

  ngOnInit(): void {
    this.gameDataService.wallpapersList.subscribe(wallpaperNames => {
      const isFirefox = BrowserUtil.isBrowserFirefox()
      const wpName = ParticlesUtil.getRandomWallpaperName(wallpaperNames)
      this.particlesOptions = isFirefox ? ParticlesUtil.getLightBackgroundBubblesOptions(wpName)
          : ParticlesUtil.getBackgroundBubblesOptions(wpName)
      this.isWallpaperLoaded = true
    })
  }

  async particlesInit(engine: Engine): Promise<void> {
    console.log(engine)

    // Starting from 1.19.0 you can add custom presets or shape here, using the current tsParticles instance (main)
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine)
  }
}
