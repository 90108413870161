import {Injectable} from "@angular/core";
import {HttpClient, HttpEvent, HttpEventType, HttpParams, HttpRequest} from "@angular/common/http";
import {catchError, last, map, tap} from "rxjs/operators";


@Injectable()
export class AdminApiService {
  private readonly base_api_url = 'http://localhost:5000/api/admin/'

  constructor(private http: HttpClient) {}

  public adminLogin(code: string) {
    const url = this.base_api_url + 'adminLogin';
    const options = { params: new HttpParams().set('code', code) }

    return this.http.get(url, options);
  }

  public fetchGamesData() {
    const url = this.base_api_url + 'fetchGamesData';
    const req = new HttpRequest('GET', url, { reportProgress: true })

    return this.http.request(req).pipe(
      map(event => this.getEventMessage(event)),
      tap(message => this.showProgress(message)),
      last() // return last (completed) message to caller
    )
    //return this.http.get(url);
  }

  public fetchHistories() {
    const url = this.base_api_url + 'fetchHistories';
    return this.http.get(url);
  }

  public fetchRankedSummoners() {
    const url = this.base_api_url + 'fetchHighRankedSummoners';

    return this.http.get(url);
  }


  private getEventMessage(event: HttpEvent<any>) {
    switch (event.type) {
      case HttpEventType.Sent:
        return `Processing request`;

      case HttpEventType.UploadProgress:
        // Compute and show the % done:
        if (!event?.total) {return}
        const percentDone = Math.round(100 * event.loaded / event.total);
        return `${percentDone}% uploaded.`;

      case HttpEventType.Response:
        return `File was completely uploaded!`;

      default:
        return `File surprising upload event: ${event.type}.`;
    }
  }

  private showProgress(message: string | undefined) {
    console.log(message)
  }
}
