import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class IconService {

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) { }

  public registerIcons(): void {
    this.loadIcons(Object.values(Icons), '../../assets/fonts/icons');
  }

  private loadIcons(iconKeys: string[], iconUrl: string): void {
    iconKeys.forEach(key => {
      this.matIconRegistry.addSvgIcon(key, this.domSanitizer.bypassSecurityTrustResourceUrl(`${iconUrl}/${key}.svg`));
    });
  }
}

export enum Icons {
  RoleTop= 'role-top',
  RoleJungle= 'role-jgl',
  RoleMid= 'role-mid',
  RoleAdc= 'role-bot',
  RoleSup= 'role-sup',
  RoleAll= 'role-all',
  ChampionPlaceholder= 'champ-placeholder'
}
