<div class="picker-help-container"> {{ getHelpLbl() }}
  <button mat-raised-button color="primary" *ngIf="isPredictButtonAvailable()" (click)="onPredict()">Predict</button>
</div>
<div class="picker-container ">
  <div class="team-column">
    <app-picker-player-button *ngFor="let ally of allies; let index = index;"
                              (onSelectRole)="onSelectRole($event)"
                              (onSelectPlayer)="onClickPlayer($event)"
                              [(player)]="allies[index]">
    </app-picker-player-button>
  </div>

  <div [hidden]="!isChampionSelectionPhase()" class="champions-selector">
    <div class="search-bar-container">
      <mat-form-field appearance="outline" [style.width.%]="100">
        <mat-label>Find a champion</mat-label>
        <input matInput #championSearchBar [(ngModel)]="searchedText" (ngModelChange)="onSearchChange()">
      </mat-form-field>
    </div>
    <div class="champions-container">
      <div *ngFor="let champion of filteredChampions; trackBy: trackByChampId"
           (click)="onPickChampion(champion)"
           class="champ-wrapper">
        <img [src]="getChampionUrl(champion)"/>
      </div>
    </div>
  </div>

  <div *ngIf="isResultsPhase()" class="results-container">
    <div *ngFor="let pred of predictions" class="champ-wrapper">
      <img [src]="getChampionUrl(pred.champion)"/>
      <span class="picker-score">{{pred.score}}</span>
    </div>
  </div>

  <div *ngIf="!isRoleSelectionPhase()" class="team-column">
    <app-picker-player-button *ngFor="let enemy of enemies"
                              (click)="onClickPlayer(enemy)"
                              [player]="enemy">
    </app-picker-player-button>
  </div>

</div>

