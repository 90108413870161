import { Component, Input, OnInit } from '@angular/core';
import { DetailedSummoner, Game, Player, SummonerIdentity } from '../../../services/summoner/summonerTypes';
import { GameDataService } from '../../../services/game-data/game-data.service';
import { Champion, QueueType } from '../../../services/game-data/game-data-api-types';
import { GetSummonerDataResult } from '../../../services/summoner/summonerApiTypes';
import { Router } from '@angular/router';
import { DateUtil } from 'src/app/Utils/DateUtil';

@Component({
  selector: 'app-summoner-match-history',
  templateUrl: './summoner-match-history.component.html',
  styleUrls: ['./summoner-match-history.component.less']
})
export class SummonerMatchHistoryComponent implements OnInit {

  @Input() public games?: Game[];
  @Input() public summoner?: DetailedSummoner;
  public allChampions?: Champion[];
  private allSummonerSpells?: any[];
  private allItems?: any[];

  constructor(
    private gameDataService: GameDataService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.loadChampions()
    this.loadSummonerSpells()
    this.loadItems()
  }

  loadChampions() {
    this.gameDataService.championsList.subscribe((champions) => {
      this.allChampions = champions
    })
  }

  loadSummonerSpells() {

    this.gameDataService.summonerSpellsList.subscribe((result: any[]) => {
      this.allSummonerSpells = result
    })

  }

  loadItems() {
    this.gameDataService.itemsList.subscribe((result: any[]) => this.allItems = result)

  }

  getChampionFromChampionNumber(championNumber: number) {
    return this.allChampions?.find(champion => champion.key == championNumber.toString())
  }

  getChampionUrl(championNumber: number) {
    const champ = this.getChampionFromChampionNumber(championNumber)
    return this.gameDataService.getChampionUrl(champ?.id ?? "");
  }
  getGameTimeFromGameDuration(gameDuration: number) {
    const minutes = Math.floor(gameDuration / 60)
    const seconds = gameDuration % 60
    return minutes + ":" + seconds
  }

  isGameWin(game: Game) {
    return this.getCurrentPlayer(game)?.stats.win
  }

  getRedSummoners(game: Game): SummonerIdentity[] {
    return this.getTeamSummoners(game, 200)
  }

  getBlueSummoners(game: Game): SummonerIdentity[] {
    return this.getTeamSummoners(game, 100)
  }

  private getTeamSummoners(game: Game, teamId: number) {
    const blueTeamPlayers = game.participants.filter(player => player.teamId == teamId)
    return game.participantIdentities.filter(summoner => {
      return blueTeamPlayers.some(player => summoner.participantId == player.participantId)
    })

  }


  getCurrentPlayerKills(game: Game) {
    const player = this.getCurrentPlayer(game)
    return player?.stats.kills
  }


  getCurrentPlayerDeaths(game: Game) {
    const player = this.getCurrentPlayer(game)
    return player?.stats.deaths
  }


  getCurrentPlayerAssists(game: Game) {
    const player = this.getCurrentPlayer(game)
    return player?.stats.assists
  }

  private getCurrentPlayer(game: Game): Player | undefined {
    const currentSummoner = game.participantIdentities.find(summoner => summoner.player.summonerId == this.summoner?.id)
    const currentPlayer = game.participants.find(participant => participant.participantId == currentSummoner?.participantId)
    return currentPlayer
  }

  getCurrentPlayerChampionUrl(game: Game) {
    const currentPlayer = this.getCurrentPlayer(game)
    return this.getChampionUrl(currentPlayer?.championId ?? 0)

  }


  getItemUrl(game: Game, itemNumber: number) {
    const itemId = this.getItemId(game, itemNumber) ?? 0
    return this.gameDataService.getItemUrl(itemId)
  }
  hasItemImage(game: Game, itemNumber: number) {
    const itemId = this.getItemId(game, itemNumber)
    return itemId != 0
  }
  private getItemId(game: Game, itemNumber: number) {
    const player = this.getCurrentPlayer(game)
    const itemsList = [player?.stats.item0, player?.stats.item1, player?.stats.item2, player?.stats.item3, player?.stats.item4, player?.stats.item5, player?.stats.item6]
    return itemsList[itemNumber]


  }
  // TODO : use real ID 
  getItemToolTip(game: Game, itemNumber: number): string {
    const itemId = this.getItemId(game, itemNumber) ?? 0
    const foundItem = this.allItems?.find(item => itemId == +item.image.full.split(".")[0])
    return foundItem.plaintext
  }
  private navigateToSummoner(region: string, summonerData: GetSummonerDataResult): void {
    this.router.navigate(
      ['/summoner', region, summonerData.name],
      { state: { summonerAccountData: summonerData } }
    );
  }

  onClickSummoner(summoner: SummonerIdentity) {
    this.router.navigate(
      ['/summoner', "EUW1", summoner.player.summonerName]
    );
  }
  getPlayerChampionUrl(game: Game, sumonner: SummonerIdentity) {
    const player = game.participants.find(participant => participant.participantId == sumonner.participantId)
    return this.getChampionUrl(player?.championId ?? 0)

  }
  getPlayerMinionScore(game: Game) {
    const player = this.getCurrentPlayer(game)
    return player?.stats.totalMinionsKilled

  }

  getMatchDateFromTimestamp(timestamp: number) {

    return DateUtil.getDateDifferenceWithCurrentDate(timestamp)

  }


  getSummonerSpellFromSpellNumber(game: Game, spellNumber: number) {
    const player = this.getCurrentPlayer(game)
    return this.allSummonerSpells?.find(summonerSpell => {
      return spellNumber == 1 ? summonerSpell.key == player?.spell1Id.toString() : summonerSpell.key == player?.spell2Id.toString()
    })

  }

  getCurrentPlayerSummonerSpell(game: Game, spellNumber: number) {
    const summonerSpellName = this.getSummonerSpellFromSpellNumber(game, spellNumber)?.id
    return this.gameDataService.getSummonerSpellUrl(summonerSpellName ?? "")

  }
  getSummonerSpellToolTip(game: Game, spellNumber: number) {
    return this.getSummonerSpellFromSpellNumber(game, spellNumber)?.description
  }

  getQueueTypeFromGame(game: Game) {
    const queueId = game.queueId
    switch (queueId) {
      case QueueType.RankedSolo: return "Ranked Solo/Duo"
      case QueueType.RankedFlex: return "Ranked Flex"
      case QueueType.ARAM: return "ARAM"

    }
    return ""
  }

}
