import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ChampionsStats} from "./championStatsTypes";

@Injectable()
export class TierListApiService {
  private readonly base_api_url = 'http://localhost:5000/api/stats'

  constructor(private http: HttpClient) {
  }


  public getAllChampionsStats() {
    const url = `${this.base_api_url}`;
    //const params

    return this.http.get<ChampionsStats>(url);
  }
}
