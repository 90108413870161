import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import {GetSummonerDataResult, GameHistoryResponse, SummonerResponse} from './summonerApiTypes';
import {GameDataService} from "../game-data/game-data.service";


@Injectable()
export class SummonerApiService {
  private readonly BASE_API_URL = 'http://localhost:5000/api/summoner';

  constructor(private http: HttpClient, private gameDataService: GameDataService) { /* noop */ }

  public getSummonerAccountData(summonerName: string, regionId: string): Observable<GetSummonerDataResult> {
    const url = `${this.BASE_API_URL}/account/${regionId}/${summonerName}`;
    return this.http.get<GetSummonerDataResult>(url);
  }

  public getSummoner(region: string, summonerId: string): Observable<SummonerResponse>{
    const url = `${this.BASE_API_URL}/elo/${region}/${summonerId}`;
    return this.http.get<SummonerResponse>(url);
  }

  public getSummonerHistory(accountId: string, startIndex=0): Observable<GameHistoryResponse>{
    const url = `${this.BASE_API_URL}/history/${accountId}`;
    const options = { params: new HttpParams().set('start_index', startIndex.toString()) }

    return this.http.get<GameHistoryResponse>(url, options);
  }

  public getSummonerIconUrl(icon: number): string {
    return `${this.gameDataService.ddApiUrl}/img/profileicon/${icon}.png`;
  }
}
