import { Component, OnInit } from '@angular/core';
import {AdminApiService} from "../../../services/admin/adminApi.service";

@Component({
  selector: 'app-mal-dashboard',
  templateUrl: './mal-dashboard.component.html',
  styleUrls: ['./mal-dashboard.component.less'],
  providers: [AdminApiService]
})
export class MalDashboardComponent implements OnInit {
  public isUserAuthorized = true;
  public currentCode = '';
  public hide = true;

  constructor(private adminApiService: AdminApiService) { }

  ngOnInit(): void {
  }

  public onTypePassword() {
    this.adminApiService.adminLogin(this.currentCode).subscribe((result: any) => {
      this.isUserAuthorized = result.isLoggedIn;
    })
  }


  onFetchGamesData() {
    this.adminApiService.fetchGamesData().subscribe();
  }

  onFetchHistories() {
    this.adminApiService.fetchHistories().subscribe();
  }

  onFetchSummoners() {
    this.adminApiService.fetchRankedSummoners().subscribe();
  }
}
