import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SummonerApiService} from '../../services/summoner/summonerApi.service';
import {GetSummonerDataResult} from '../../services/summoner/summonerApiTypes';

@Component({
  selector: 'app-summoner',
  templateUrl: './summoner.component.html',
  styleUrls: ['./summoner.component.less'],
  providers: [SummonerApiService]
})
export class SummonerComponent implements OnInit {

  public uiState: SummonerUiState = SummonerUiState.Loading;
  public uiStates = SummonerUiState;

  public region = '';
  public name = '';
  public accountData: GetSummonerDataResult | null = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private summonerApiService: SummonerApiService
  ) {
    this.route.paramMap.subscribe(params => {
      this.name = params.get('summoner') as string;
      this.region = params.get('region') as string;
      const summonerAccountData = this.router.getCurrentNavigation()?.extras?.state?.summonerAccountData;
      const showAlternatives = this.router.getCurrentNavigation()?.extras?.state?.showAlternatives;
      if (showAlternatives === true) {
        this.uiState = SummonerUiState.Alternatives;
      } else {
        if (this.region != null && summonerAccountData != null) {
          this.onAccountDataSuccess(this.region, summonerAccountData);
        }
        if (this.name != null && this.region != null) {
          this.getAccountData(this.name, this.region);
        }
      }
    });
  }

  ngOnInit(): void {
    // noop
  }

  getAccountData(name: string, region: string): void {
    this.summonerApiService.getSummonerAccountData(name, region)
      .subscribe({
        next: (accountData: GetSummonerDataResult) => {
          this.onAccountDataSuccess(region, accountData);
        },
        error: msg => {
          console.log(msg);
          if (msg.error.code === 404) {
            this.uiState = SummonerUiState.Alternatives;
          } else {
            // TODO show snackbar and retry button
          }
        }
      });
  }

  onAccountDataSuccess(region: string, accountData: GetSummonerDataResult): void {
    this.uiState = SummonerUiState.Content;
    this.region = region;
    this.accountData = accountData;
  }
}

enum SummonerUiState {
  Loading, Content, Alternatives
}
