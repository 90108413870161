<div class="summoner-alternatives-container">
  <p class="center title">We couldn't find summoner "{{name}}"</p>
  <p class="center subtitle">Check your spelling or use another region</p>
  <div *ngIf="available.length > 0 && loadingCount == 0" class="alternatives-regions">
    <div *ngFor="let alternative of available">
      <div *ngIf="alternative?.accountData != null">
        <button mat-button class="alternative-region-container" (click)="onRegionClick(alternative)">
          <span class="alternative-region">{{alternative.region.shortcut}} </span>
          <span class="summoner-name">{{alternative?.accountData?.name}}</span>
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="available.length == 0 && loadingCount == 0" class="alternatives-regions">
    <p class="center subtitle">We haven't found this summoner in any region</p>
  </div>
  <app-loader *ngIf="loadingCount > 0" class="loader"></app-loader>
</div>
