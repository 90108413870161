export class GetDataFromLangParameter {
  lang: string
  constructor(lang: string) {
    this.lang = lang;
  }
}

export interface GetAllChampionsResult {
  champions: [Champion]
}

export interface Champion {
  id: ChampionId;
  key: string;
  name: string;
  title: string;
  lore: string;
  blurb: string;
  allytips: [string];
  enemytips: [string];
  tags: [ChampionTag]
  skins: ChampionSkin[]
  stats: ChampionStats
  spells: ChampionSpell[]
  alias?: string
}

export interface ChampionSkin {
  chromas: boolean;
  id: string;
  name: string;
  num: string
}

export interface ChampionStats {
  armor: number
  armorperlevel: number
  attackdamage: number
  attackdamageperlevel: number
  attackrange: number
  attackspeed: number
  attackspeedperlevel: number
  crit: number
  critperlevel: number
  hp: number
  hpperlevel: number
  hpregen: number
  hpregenperlevel: number
  movespeed: number
  mp: number
  mpperlevel: number
  mpregen: number
  mpregenperlevel: number
  spellblock: number
  spellblockperlevel: number
}

export interface ChampionSpell {
  image: any
}

export enum ChampionTag {
  Assassin = "Assassin",
  Mage = "Mage",
  Tank = "Tank",
  Fighter = "Fighter",
  Marksman = "Marksman",
  Support = "Support"
}
//TODO code this properly 

export enum QueueType {
  RankedSolo = 420,
  RankedFlex = 440,
  ARAM = 450,
}


export interface GetRegionResult {
  regions: [Region];
}

export interface Region {
  apiKey: string;
  shortcut: string;
}

export interface GetWallpaperResult {
  wallpaperNames: string[];
}

export type ChampionId = string
