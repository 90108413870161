import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationStart, Router} from '@angular/router';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.less']
})
export class NavigationBarComponent implements OnInit {

  public showSearchBar = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {
    // noop
  }

  ngOnInit(): void {
    this.route.url.subscribe(value => {
      this.showSearchBar = value[0].path !== '';
    });

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        this.showSearchBar = val.url !== '/';
      }
    });
  }

  public onMobileSearch(): void {
    this.router.navigate(['/']);
  }
}
