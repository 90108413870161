export class DateUtil {

    public static getDateDifferenceWithCurrentDate(timestamp: number) {

        const timeDifference = new Date().getTime() - timestamp
        const timeInMinutes = Math.floor(timeDifference / (60 * 1000))

        if (timeInMinutes < 60) {
            return timeInMinutes + " minutes ago"
        }

        const timeInHours = Math.floor(timeInMinutes / 60)
        if (timeInHours < 24) {
            return timeInHours + " hour(s) ago"
        }

        const timeInDays = Math.floor(timeInHours / 24)
        if (timeInDays < 30) {
            return timeInDays + " day(s) ago"
        }

        const timeInMonths = Math.floor(timeInDays / 30)
        if (timeInMonths < 12) {
            return timeInMonths + " Month(s) ago"
        }
        return "a long time ago"
    }
}