import {Icons} from "../services/icon.service";

export class RolesUtil {

  public static getGlyphFromRole(role?: Role) {
    switch (role) {
      case Role.Top:
        return Icons.RoleTop
      case Role.Jungle:
        return Icons.RoleJungle
      case Role.Mid:
        return Icons.RoleMid
      case Role.Adc:
        return Icons.RoleAdc
      case Role.Support:
        return Icons.RoleSup
      default:
        return Icons.RoleAll
    }
  }

  public static getRoleFromIndex(role_index: number) {
    return [Role.Top, Role.Jungle, Role.Mid, Role.Adc, Role.Support][role_index]
  }
}


export enum Role {
  Top     = "TOP",
  Jungle  = "JUN",
  Mid     = "MID",
  Adc     = "BOT",
  Support = "SUP"
}
