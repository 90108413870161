import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {GetSummonerDataResult} from '../../services/summoner/summonerApiTypes';
import {SummonerApiService} from '../../services/summoner/summonerApi.service';
import {Router} from '@angular/router';
import {Champion, Region} from "../../services/game-data/game-data-api-types";
import {GameDataService} from "../../services/game-data/game-data.service";
import {MatMenuTrigger} from "@angular/material/menu";

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.less'],
  providers: [SummonerApiService]
})
export class SearchBarComponent implements OnInit {

  public searchedTerm = '';
  public selectedRegion?: Region;
  public regions: Array<Region> = [];
  public allChampions: Champion[] = [];
  public suggestedChamps: Champion[] = [];
  public isSearchLoading = false;
  public isResultsVisible = false;

  @Input() public searchBarType = 'normal';
  @ViewChild('searchInput') private inputEl ?: ElementRef;

  constructor(
    private summonerApiService: SummonerApiService,
    private router: Router,
    private gameDataService: GameDataService
  ) { }

  ngOnInit(): void {
    this.gameDataService.regions.subscribe((regions: Array<Region>) => {
      this.regions = regions;
      if (this.regions.length > 0) {
        this.selectedRegion = this.regions[0];
      }
    });

    this.gameDataService.championsList.subscribe(champs => {
      this.allChampions = champs
    })
  }

  getClass(): string {
    return `search-bar ${this.searchBarType}`;
  }

  // Triggered on Enter or Search Click
  onSearch(): void {
    this.inputEl?.nativeElement.blur();
    if (this.selectedRegion != undefined && this.searchedTerm != '') {
      this.searchSummoner(this.selectedRegion.apiKey, this.searchedTerm);
    }
  }

  private searchSummoner(region: string, name: string): void {
    this.isSearchLoading = true;
    this.summonerApiService.getSummonerAccountData(name, region)
      .subscribe({
        next: (summonerData: GetSummonerDataResult) => {
          if (summonerData) {
            this.isSearchLoading = false;
            this.navigateToSummoner(region, summonerData);
          } else {
            this.isSearchLoading = false;
            this.navigateToRegionAlternatives(region, name);
          }
        },
        error: msg => {
          this.isSearchLoading = false;
          if (msg.error.code === 404) {
            this.navigateToRegionAlternatives(region, name);
          } else {
            console.log(msg);
            // TODO show snackbar for internal error
          }
        }
      });
  }

  private navigateToSummoner(region: string, summonerData: GetSummonerDataResult): void {
    this.router.navigate(
      ['/summoner', region, summonerData.name],
      {state: {summonerAccountData: summonerData}}
    );
  }

  private navigateToRegionAlternatives(region: string, summonerName: string): void {
    this.router.navigate(
      ['/summoner', region, summonerName],
      {state: {showAlternatives: true}}
    );
  }

  onPreSearch() {
    if (!this.searchedTerm) {
      this.suggestedChamps = []
    }
    else {
      const searchTerm = this.searchedTerm.toLowerCase()
      this.suggestedChamps = this.allChampions.filter(champ => {
        return champ.id.toLowerCase().startsWith(searchTerm)
          || champ.name.toLowerCase().startsWith(searchTerm)
          || champ.alias?.toLowerCase().startsWith(searchTerm)
      })
    }
    this.isResultsVisible = !!this.suggestedChamps.length
  }

  onFocus(isFocused: boolean) {
    this.isResultsVisible = isFocused ? !!this.suggestedChamps.length : false
  }

  onClickChampion(champion: Champion) {
    this.router.navigate(['/champion', champion.id])
  }

  getChampionImgUrl(champion: Champion): string {
    return this.gameDataService.getChampionUrl(champion.id)
  }
}
