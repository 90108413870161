import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {
  GetDataFromLangParameter,
  GetAllChampionsResult,
  GetRegionResult,
  GetWallpaperResult
} from "./game-data-api-types";
import {Observable} from "rxjs";


@Injectable()
export class GameDataApiService {
  private readonly base_api_url = 'http://localhost:5000/api/game-data'

  constructor(private http: HttpClient) {
  }

  public getCurrentVersion() {
    const url = `${this.base_api_url}/getVersion`;

    return this.http.get(url, {responseType: "text"});
  }

  // TODO add language management
  public getAllChampions(language: string = 'en_US') {
    const parameter = new GetDataFromLangParameter(language);
    const url = `${this.base_api_url}/getAllChampions`;
    const options = { params: <any> parameter }

    return this.http.get<GetAllChampionsResult>(url, options);
  }


  // TODO add language management
  public getAllItems(language: string = 'en_US') {
    const parameter = new GetDataFromLangParameter(language);
    const url = `${this.base_api_url}/getAllItems`;
    const options = { params: <any> parameter }

    return this.http.get<any>(url, options);
  }

  // TODO add language management
  public getAllSummonerSpells(language: string = 'en_US') {
    const parameter = new GetDataFromLangParameter(language);
    const url = `${this.base_api_url}/getAllSummonerSpells`;
    const options = { params: <any> parameter }

    return this.http.get<any>(url, options);
  }

  public getRegions(): Observable<GetRegionResult> {
    const url = `${this.base_api_url}/getAllRegions`;
    return this.http.get<GetRegionResult>(url);
  }

  public getWallpaperFileName() {
    const url = `${this.base_api_url}/getWallpapers`;
    return this.http.get<GetWallpaperResult>(url);
  }
}
