<mat-card class="rank-container">
  <span class="rank-title">{{rankData?.queue}}</span>
  <div class="rank-emblem-container">
    <img class="tier-emblem" [src]="rankData?.emblem" alt="Summoner tier emblem">
  </div>
  <span class="rank-info">
    {{rankData?.tier |titlecase}} {{rankData?.rank}} -
    {{rankData?.leaguePoints}}LP
  </span>
  <span class="rank-content">
    <b>{{rankData?.winRate}}% WR</b> - {{rankData?.gameCount}} games
  </span>
</mat-card>
