<div [hidden]="!hasChampionsData" class="tier-list-container">
  <div class="filters-bar">
    <mat-form-field appearance="outline" [style.width.px]="400" [style.font-size.px]="12" [style.margin-bottom.em]="-1.25">
      <mat-label>Find a champion</mat-label>
      <input matInput [(ngModel)]="searchTerm" (ngModelChange)="onSearchChampion()">
    </mat-form-field>
    <!--input matInput class="champion-search" (keyup)="onSearchChampion($event)" placeholder="Search any champion"-->
    <div class="role-filters">
      <div *ngFor="let role of rolesList" class="role-btn"
           [ngClass]="{'selected-role': isCurrentSelectedRole(role)}"
           (click)="onClickRoleFilter(role)">
        <mat-icon svgIcon="{{getRoleGlyphFromRole(role)}}"></mat-icon>
      </div>
    </div>
  </div>
  <div class="table-container">
    <table mat-table [dataSource]="tableDataSource" matSort matSortActive="winRate" matSortDirection="desc" matSortDisableClear>

      <ng-container matColumnDef="rank">
        <mat-header-cell *matHeaderCellDef>Rank </mat-header-cell>
        <mat-cell *matCellDef="let championStats; let i = index">{{i + 1}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="role">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Role</mat-header-cell>
        <mat-cell *matCellDef="let championStats">
          <mat-icon svgIcon="{{getRoleClassFromRoleIndex(championStats.role)}}"></mat-icon>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="championId">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Champion</mat-header-cell>
        <mat-cell *matCellDef="let championStats">
          <div (click)="onClickChampion(championStats)" class="champion-container">
            <div class="crop-container">
            <img [src]="getChampionUrl(championStats.championId)" class="champion-round"/>
            </div>
            {{getChampionName(championStats)}}
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="tier">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Tier</mat-header-cell>
        <mat-cell *matCellDef="let championStats">
          <span class="tier-wrapper" [ngClass]="getTierClass(championStats.tier)"> {{championStats.tier}} </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="winRate">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Win Rate</mat-header-cell>
        <mat-cell *matCellDef="let championStats"> {{championStats.winRate}} %</mat-cell>
      </ng-container>

      <ng-container matColumnDef="pickRate">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Pick Rate</mat-header-cell>
        <mat-cell *matCellDef="let championStats"> {{championStats.pickRate}} % </mat-cell>
      </ng-container>

      <ng-container matColumnDef="totalPlayed">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Matches</mat-header-cell>
        <mat-cell *matCellDef="let championStats"> {{championStats.totalPlayed}}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: columnsToDisplay;"></mat-row>
    </table>
  </div>

  <app-loader *ngIf="!hasChampionsData" class="loader"></app-loader>
</div>
