import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MalDashboardComponent} from './components/admin/mal-dashboard/mal-dashboard.component';
import {HomePageComponent} from './components/home-page/home-page.component';
import {PickerComponent} from './components/picker/picker.component';
import {PageNotFoundComponent} from './components/page-not-found/page-not-found.component';
import {SummonerComponent} from './components/summoner/summoner.component';
import {TierListComponent} from './components/tier-list/tier-list.component';
import {ChampionPageComponent} from "./components/champion/champion-page/champion-page.component";

const routes: Routes = [
  {path: '', component: HomePageComponent},
  {path: 'picker', component: PickerComponent},
  {path: 'tier-list', component: TierListComponent},
  {path: 'summoner/:region/:summoner', component: SummonerComponent},
  {path: 'champion/:championId', component: ChampionPageComponent},
  {path: 'admin', component: MalDashboardComponent},
  {path: '**', component: PageNotFoundComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
