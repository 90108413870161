import {
  Component,
  HostListener,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  DetailedSummonerResponse,
  GetSummonerDataResult,
  GameHistoryResponse,
  SummonerResponse
} from '../../../services/summoner/summonerApiTypes';
import { SummonerApiService } from '../../../services/summoner/summonerApi.service';
import { DetailedSummoner, Game, SummonerQueue } from '../../../services/summoner/summonerTypes';
import {GameDataService} from "../../../services/game-data/game-data.service";

@Component({
  selector: 'app-summoner-overview',
  templateUrl: './summoner-overview.component.html',
  styleUrls: ['./summoner-overview.component.less'],
  providers: [SummonerApiService]
})
export class SummonerOverviewComponent implements OnChanges {

  @Input() accountData!: GetSummonerDataResult;
  @Input() region!: string;

  public summoner?: DetailedSummoner;
  public games?: Game[];

  public isHistoryLoading = false;
  public isLoadingMoreGames = false;
  public isSummonerLoading = true;
  private imageGradient = ""

  public get backgroundImageStyle() { return {backgroundImage: this.imageGradient}}


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private summonerApiService: SummonerApiService,
    private gameDataService: GameDataService,
  ) {
  }

  //todo called twice on init
  ngOnChanges(changes: SimpleChanges): void {
    this.loadSummoner(this.region, this.accountData);
    this.loadHistory(this.accountData)
    this.loadChampions()
  }


  @HostListener("scroll", ["$event"])
  onHistoryScroll(event: any) {
    // visible height + pixel scrolled >= total height
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 50) {
      this.onLoadMoreGames()
    }
  }

  loadSummoner(region: string, summonerAccountData: GetSummonerDataResult): void {
    this.summonerApiService.getSummoner(region, summonerAccountData.id)
      .subscribe((response: SummonerResponse) => {
        this.onSummonerLoaded(response, summonerAccountData);
      });
  }

  loadHistory(summonerAccountData: GetSummonerDataResult): void {
    this.isHistoryLoading = true
    this.summonerApiService.getSummonerHistory(summonerAccountData.accountId)
      .subscribe((response: GameHistoryResponse) => {
        this.games = response.games
        this.isHistoryLoading = false
      });
  }

  // TODO : replace with most played champ
  loadChampions(): void {
    this.gameDataService.championsList.subscribe(champions => {
      if (!champions?.length) {return}
      const randomChampion = champions[Math.floor(Math.random() * champions.length)]
      const randomSkin = randomChampion.skins[Math.floor(Math.random() * randomChampion.skins.length)]
      console.log(randomSkin)
      this.imageGradient = "linear-gradient(270deg, rgba(9, 13, 49, 0), #090D31)," +
        "linear-gradient(90deg, rgba(9, 13, 49, 0), #090D31)," +
        `url(\"https://ddragon.leagueoflegends.com/cdn/img/champion/splash/${randomChampion.id}_${randomSkin.num}.jpg\")`
    })
  }

  onSummonerLoaded(response: SummonerResponse, accountData: GetSummonerDataResult): void {
    const imageUrl = this.summonerApiService.getSummonerIconUrl(accountData.profileIconId);
    const rankedSummoner = response.summoner.find(x => x.queueType === 'RANKED_SOLO_5x5');
    const flexSummoner = response.summoner.find(x => x.queueType === 'RANKED_FLEX_SR');
    this.summoner = this.mapDetailedSummoner(accountData, rankedSummoner, imageUrl, flexSummoner);
    this.isSummonerLoading = false;
  }

  private mapDetailedSummoner(
    accountData: GetSummonerDataResult,
    rankedSummoner: DetailedSummonerResponse | undefined,
    imageUrl: string,
    flexSummoner: DetailedSummonerResponse | undefined
  ): DetailedSummoner {
    let rankedData;
    if (rankedSummoner) {
      rankedData = new SummonerQueue(
        rankedSummoner.queueType,
        rankedSummoner.leaguePoints,
        rankedSummoner.wins,
        rankedSummoner.losses,
        rankedSummoner.tier,
        rankedSummoner.rank,
        rankedSummoner.veteran,
        rankedSummoner.freshBlood,
        rankedSummoner.hotStreak,
        rankedSummoner.inactive,
        this.getEmblem(rankedSummoner.tier)
      );

    }

    let flexData;
    if (flexSummoner) {
      flexData = new SummonerQueue(
        flexSummoner.queueType,
        flexSummoner.leaguePoints,
        flexSummoner.wins,
        flexSummoner.losses,
        flexSummoner.tier,
        flexSummoner.rank,
        flexSummoner.veteran,
        flexSummoner.freshBlood,
        flexSummoner.hotStreak,
        flexSummoner.inactive,
        this.getEmblem(flexSummoner.tier)
      );
    }
    return new DetailedSummoner(
      accountData.id,
      accountData.name,
      accountData.summonerLevel,
      imageUrl,
      rankedData,
      flexData
    );
  }

  getEmblem(tier: string): string {
    return `assets/images/summoner/emblems/${tier}.png`;
  }

  onLoadMoreGames() {
    if (this.isLoadingMoreGames) { return }
    this.isLoadingMoreGames = true
    this.summonerApiService.getSummonerHistory(this.accountData.accountId, this.games?.length)
      .subscribe(result => {
        this.games?.push(...result.games)
        this.isLoadingMoreGames = false
      })
  }

}



