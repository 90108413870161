<div class="search-bar-container">
  <div [ngClass]="getClass()">
    <mat-select class="region-selector" [(value)]="selectedRegion" disableOptionCentering panelClass="regions-panel">
      <mat-option *ngFor="let region of regions" [value]="region"> {{ region.shortcut }}
      </mat-option>
    </mat-select>
    <input [(ngModel)]="searchedTerm"
           #searchInput
           class="search-input"
           placeholder="Champion, Summoner Name ..."
           (focusin)="onFocus(true)"
           (focusout)="onFocus(false)"
           (keyup)="onPreSearch()"
           (keyup.enter)="onSearch()">
    <span class="search-btn" (click)="onSearch()">
    <i *ngIf="!isSearchLoading" class="material-icons">search</i>
    <app-loader *ngIf="isSearchLoading" loaderType="mini"></app-loader>
  </span>
  </div>

  <ul [hidden]="!isResultsVisible" class="champions-result">
    <li (mousedown)="onClickChampion(champion)" *ngFor="let champion of suggestedChamps">
      <img [src]="getChampionImgUrl(champion)" alt="champion-img">
      {{champion.name}}
    </li>
  </ul>
</div>
