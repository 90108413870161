import {Component, OnInit, ViewChild} from '@angular/core';
import {TierListService} from "../../services/tier-list/tier-list.service";
import {ChampionsStats, ChampionStats} from "../../services/tier-list/championStatsTypes";
import {Champion, ChampionId} from "../../services/game-data/game-data-api-types";
import {GameDataService} from "../../services/game-data/game-data.service";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {Role, RolesUtil} from "../../Utils/RolesUtil";
import {Router} from "@angular/router";

@Component({
  selector: 'app-tier-list',
  templateUrl: './tier-list.component.html',
  styleUrls: ['./tier-list.component.less'],
  providers: [TierListService]
})
export class TierListComponent implements OnInit {

  public tableDataSource: MatTableDataSource<DisplayedChampionStats> = new MatTableDataSource<DisplayedChampionStats>()
  public filteredChampionsStats: DisplayedChampionStats[] = []
  public allChampionsStats: DisplayedChampionStats[] = []
  public currentSelectedRole?: Role
  public champions: Champion[] = []
  public searchTerm = ""
  hasChampionsData = false;
  columnsToDisplay = ['rank', 'role', 'championId', 'tier', 'winRate', 'pickRate', 'totalPlayed']

  @ViewChild(MatSort) sort: MatSort | null = null;
  public rolesList = [undefined, Role.Top, Role.Jungle, Role.Mid, Role.Adc, Role.Support]


  constructor(private tierListService: TierListService,
              private gameDataService: GameDataService,
              private router: Router) { }

  ngOnInit(): void {
    this.gameDataService.championsList.subscribe((champions: Champion[]) => this.champions = champions)
    this.tierListService.championsStats.subscribe((value: ChampionsStats) => {
      if (!value) { return }
      this.onRefreshChampionsStats(value.champions, value.totalGames)
      this.hasChampionsData = true
    })
  }

  public getChampionUrl(championId: string) {
    return this.gameDataService.getChampionUrl(championId);
  }

  public getChampionName(champStats: DisplayedChampionStats): string {
      return champStats.champion?.name ?? this.champions.find(champion => champion.id == champStats.championId)?.name ?? ""
  }

  public getRoleClassFromRoleIndex(role_index: number) {
    const role = RolesUtil.getRoleFromIndex(role_index)
    return this.getRoleGlyphFromRole(role)
  }

  public getRoleGlyphFromRole(role?: Role) {
    return RolesUtil.getGlyphFromRole(role)
  }

  public isCurrentSelectedRole(role?: Role) {
   return this.currentSelectedRole == role
  }

  public getTierClass(tier: string) {
    return tier == "S+" ? "S-plus" : tier;
  }

  public onClickRoleFilter(role?: Role) {
    this.onChangeRoleFilter(role)
    this.currentSelectedRole = role
  }

  public onChangeRoleFilter(selectedRole?: Role) {
    if (!selectedRole) {
      this.filteredChampionsStats = this.allChampionsStats
    }
    else {
      this.filteredChampionsStats = this.allChampionsStats.filter(champStats =>
        RolesUtil.getRoleFromIndex(champStats.role) == selectedRole)
    }
    this.tableDataSource.data = this.filteredChampionsStats
  }

  public onSearchChampion() {
    const filterValue = this.searchTerm.toLowerCase();
    if (!filterValue) { this.tableDataSource.data = this.filteredChampionsStats}
    else {
      this.tableDataSource.data = this.filteredChampionsStats.filter(champStats => {
        let alias = champStats.champion?.alias
        return this.getChampionName(champStats).toLowerCase().indexOf(filterValue) != -1
        || champStats.championId.toLowerCase().indexOf(filterValue) != -1
        || (alias && alias.indexOf(filterValue) != -1)
      })
    }
  }

  public onClickChampion(champion: ChampionStats) {
    this.router.navigate(['/champion', champion.championId])
  }

  private onRefreshChampionsStats(stats: ChampionStats[], totalGames: number) {
    this.allChampionsStats = stats.map(champStat =>
      new DisplayedChampionStats(champStat.championId, champStat.win, champStat.total, totalGames, champStat.tier, champStat.role, this.champions))
    this.filteredChampionsStats = this.allChampionsStats.slice()
    this.tableDataSource = new MatTableDataSource<DisplayedChampionStats>(this.filteredChampionsStats)
    this.tableDataSource.sort = this.sort
    this.tableDataSource.sortingDataAccessor = (champStat, property) => {
      switch (property) {
        case "championId": return this.getChampionName(champStat);
        case "tier": return this.getTierSorting(champStat.tier)
        default: return (champStat as any)[property]
      }
    }
  }

  private getTierSorting(tier: string) {
    switch (tier) {
      case "S+" : return 0
      case "S" : return 1
      case "A" : return 2
      case "B" : return 3
      case "C":
      default:
        return 4

    }
  }
}

class DisplayedChampionStats {
  championId: ChampionId
  champion?: Champion
  winRate: number
  pickRate: number
  totalPlayed: number
  role: number
  tier: string

  // TODO tier is an enum
  // TODO get champ name on construction
  constructor(championId: string, wins: number, played: number, total: number, tier: string, role: number, champions?: Champion[]) {
    this.championId = championId
    this.winRate = Math.round(wins / played * 100 * 100) / 100
    this.totalPlayed = played
    this.pickRate = Math.round(played / total * 100 *100) / 100
    this.tier = tier
    this.role = role

    this.champion = champions?.find(champ => champ.id == championId)
  }
}
