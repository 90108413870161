import { SummonerQueueType } from './summonerConstants';

export class DetailedSummoner {
  // id is Summoner id
  public id: string;
  public name: string;
  public level: number;
  public imageUrl: string;
  public rankedSoloData?: SummonerQueue;
  public rankedFlexData?: SummonerQueue;

  constructor(
    id: string,
    name: string,
    level: number,
    imageUrl: string,
    rankedSoloData?: SummonerQueue,
    rankedFlexData?: SummonerQueue
  ) {
    this.id = id;
    this.name = name;
    this.level = level;
    this.imageUrl = imageUrl;
    this.rankedSoloData = rankedSoloData;
    this.rankedFlexData = rankedFlexData;
  }
}

export class SummonerQueue {
  public queueType: SummonerQueueType;
  public queue: string;
  public leaguePoints: number;
  public wins: number;
  public losses: number;
  public tier: string;
  public rank: string;
  public veteran: boolean;
  public freshBlood: boolean;
  public hotStreak: boolean;
  public inactive: boolean;
  public emblem: string;

  public winRate: string;
  public gameCount: number;

  constructor(
    queueType: string,
    leaguePoints: number,
    wins: number,
    losses: number,
    tier: string,
    rank: string,
    veteran: boolean,
    freshBlood: boolean,
    hotStreak: boolean,
    inactive: boolean,
    emblem: string
  ) {
    this.queueType = (SummonerQueueType as any)[queueType];
    this.leaguePoints = leaguePoints;
    this.wins = wins;
    this.losses = losses;
    this.tier = tier;
    this.rank = rank;
    this.veteran = veteran;
    this.freshBlood = freshBlood;
    this.hotStreak = hotStreak;
    this.inactive = inactive;
    this.emblem = emblem.toLowerCase();

    this.winRate = ((100 * this.wins) / (this.losses + this.wins)).toFixed(0);
    this.gameCount = this.wins + this.losses;
    switch (this.queueType) {
      case SummonerQueueType.RANKED_SOLO_5x5:
        this.queue = 'Ranked Solo';
        break;
      case SummonerQueueType.RANKED_FLEX_SR:
        this.queue = 'Ranked Flex';
        break;
    }
  }
}

export interface Game {
  gameCreation: number;
  gameDuration: number;
  gameId: number;
  gameMode: string;
  gameType: string;
  participantIdentities: SummonerIdentity[];
  participants: Player[];
  queueId: number;

}

export interface SummonerIdentity {
  participantId: number;
  player: Summoner;
}

export interface Summoner {
  accountId: string;
  summonerId: string;
  summonerName: string;
}

export interface Player {
  championId: number;
  participantId: number;
  stats: PlayerStatistics;
  teamId: number;
  spell1Id: number;
  spell2Id: number;

}

export interface PlayerStatistics {
  kills: number;
  deaths: number;
  assists: number;
  win: boolean;
  totalMinionsKilled: number;
  item0: number;
  item1: number;
  item2: number;
  item3: number;
  item4: number;
  item5: number;
  item6: number;
}



