<ul class="history-container">
  <li *ngFor="let game of games" class="match-container">

    <mat-card [ngClass]="{'lose-card' : !isGameWin(game)}">
      <div class="match-header">
        <!-- Todo faire un pipe ici -->
        <div class="time-container">
          {{getGameTimeFromGameDuration(game.gameDuration)}}
        </div>
        <span class="match-date">
          {{getMatchDateFromTimestamp(game.gameCreation)}}
        </span>
        <div class="queue-type">{{getQueueTypeFromGame(game)}}</div>
      </div>

      <div class="match-data">
        <div class="champion-wrapper">
          <img [src]="getCurrentPlayerChampionUrl(game)" [ngClass]="{'lose-border' : !isGameWin(game)}"
            class="champion-img" />
        </div>
        <div class="summoner-spells-container">
          <img [src]="getCurrentPlayerSummonerSpell(game, 1)" matTooltip="{{getSummonerSpellToolTip(game,1)}}"
            matTooltipClass="Tooltip" />
          <img [src]="getCurrentPlayerSummonerSpell(game, 2)" matTooltip="{{getSummonerSpellToolTip(game,2)}}" />
        </div>
        <div class="kda-container">
          <span class="kda-stats"> {{getCurrentPlayerKills(game)}} </span>
          /
          <span class="kda-stats death-stat"> {{getCurrentPlayerDeaths(game)}} </span>
          /
          <span class="kda-stats"> {{getCurrentPlayerAssists(game)}} </span>

        </div>

        <div class="items-container">

          <div class="stuff-container">

            <div class="item-container-1">
              <img *ngIf="hasItemImage(game,0)" [src]="getItemUrl(game,0)" class="item-img"
                [matTooltip]="getItemToolTip(game,0)">
              <img *ngIf="hasItemImage(game,1)" [src]="getItemUrl(game,1)" class="item-img"
                [matTooltip]="getItemToolTip(game,1)">
              <img *ngIf="hasItemImage(game,2)" [src]="getItemUrl(game,2)" class="item-img"
                [matTooltip]="getItemToolTip(game,2)">
            </div>
            <div class="item-container-2">
              <img *ngIf="hasItemImage(game,3)" [src]="getItemUrl(game,3)" class="item-img"
                [matTooltip]="getItemToolTip(game,3)">
              <img *ngIf="hasItemImage(game,4)" [src]="getItemUrl(game,4)" class="item-img"
                [matTooltip]="getItemToolTip(game,4)">
              <img *ngIf="hasItemImage(game,5)" [src]="getItemUrl(game,5)" class="item-img"
                [matTooltip]="getItemToolTip(game,5)">
            </div>
          </div>

          <img *ngIf="hasItemImage(game,6)" [src]="getItemUrl(game,6)" class="item-img"
            [matTooltip]="getItemToolTip(game,6)">

        </div>

        <div class="last-hit-container">
          {{getPlayerMinionScore(game)}} CS
        </div>

        <div class="teams-container">
          <div class="team-container blue-team">
            <div *ngFor="let summoner of getBlueSummoners(game)" (click)="onClickSummoner(summoner)"
              class="summoner-name">
              <span> {{summoner.player.summonerName}} </span>
              <img [src]="getPlayerChampionUrl(game, summoner)" class="champion-player-img" />
            </div>
          </div>

          <div class="team-container">
            <div *ngFor="let summoner of getRedSummoners(game)" (click)="onClickSummoner(summoner)"
              class="summoner-name">
              <img [src]="getPlayerChampionUrl(game, summoner)" class="champion-player-img" />
              <span> {{summoner.player.summonerName}} </span>
            </div>
          </div>

        </div>
      </div>
    </mat-card>
  </li>
</ul>