import { Champion, GetAllChampionsResult, GetRegionResult, GetWallpaperResult, Region } from "./game-data-api-types";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { GameDataApiService } from "./game-data-api.service";

@Injectable({
  providedIn: 'root',
})
export class GameDataService {
  private _championsList: BehaviorSubject<any> = new BehaviorSubject([]);
  public readonly championsList: Observable<Array<Champion>> = this._championsList.asObservable()

  private _itemsList: BehaviorSubject<any> = new BehaviorSubject([]);
  public readonly itemsList: Observable<any> = this._itemsList.asObservable()

  private _summonerSpellsList: BehaviorSubject<any> = new BehaviorSubject([]);
  public readonly summonerSpellsList: Observable<any> = this._summonerSpellsList.asObservable()

  private _regions: BehaviorSubject<any> = new BehaviorSubject([]);
  public readonly regions: Observable<Array<Region>> = this._regions.asObservable();

  private _wallpapersList: BehaviorSubject<any> = new BehaviorSubject([]);
  public readonly wallpapersList: Observable<string[]> = this._wallpapersList.asObservable()

  private _currentGameVersion = ""
  public get currentGameVersion() { return this._currentGameVersion }

  private readonly DD_API_BASE_URL = 'http://ddragon.leagueoflegends.com/cdn';
  public get ddApiUrl() { return `${this.DD_API_BASE_URL}/${this.currentGameVersion}` }

  private static get championsAlias(): ChampionAlias[] {
    return [
      { championId: "TwistedFate", alias: "tf" },
      { championId: "TahmKench", alias: "tk" },
      { championId: "JarvanIV", alias: "j4" },
      { championId: "Gangplank", alias: "gp" },
      { championId: "AurelionSol", alias: "asol" },
      { championId: "MissFortune", alias: "mf" },
      { championId: "Leblanc", alias: "lb" },
    ]
  }

  constructor(private gameDataApiService: GameDataApiService) {
    this.getCurrentVersion();
    this.getAllWallpapersNames();
    this.fetchAllChampions();
    this.fetchAllItems();
    this.fetchAllSummonerSpells();
    this.fetchAllRegions();
  }

  public getChampionUrl(champId: string) {
    return `${this.ddApiUrl}/img/champion/${champId}.png`
  }

  public getChampionSpellUrl(spellId: string) {
    return `${this.ddApiUrl}/img/spell/${spellId}`
  }

  public getChampionPassiveUrl(champId: string) {
    return `${this.ddApiUrl}/img/passive/${champId}_P.png`
  }

  public getItemUrl(itemId: number) {
    return `${this.ddApiUrl}/img/item/${itemId}.png`
  }
  //TODO CORRECT THIS
  public getSummonerSpellUrl(spellId: string) {
    return `${this.ddApiUrl}/img/spell/${spellId}.png`
  }

  private getCurrentVersion() {
    this.gameDataApiService.getCurrentVersion().subscribe((result) => {
      this._currentGameVersion = result
    })
  }

  private fetchAllChampions() {
    this.gameDataApiService.getAllChampions().subscribe((result: GetAllChampionsResult) => {
      GameDataService.championsAlias.forEach(aliasItem => {
        const foundChampion = result.champions.find(champ => champ.id.toLowerCase() == aliasItem.championId.toLowerCase())
        if (foundChampion) { foundChampion.alias = aliasItem.alias }
      })
      this._championsList.next(result.champions)
    })
  }

  private fetchAllItems() {
    this.gameDataApiService.getAllItems().subscribe((result: any) => {
      this._itemsList.next(result.items)
    })
  }

  private fetchAllSummonerSpells() {
    this.gameDataApiService.getAllSummonerSpells().subscribe((result: any) => {
      this._summonerSpellsList.next(result.spells)
    })
  }

  private fetchAllRegions(): void {
    this.gameDataApiService.getRegions().subscribe((result: GetRegionResult) => {
      this._regions.next(result.regions);
    });
  }

  private getAllWallpapersNames() {
    this.gameDataApiService.getWallpaperFileName().subscribe((result: GetWallpaperResult) => {
      this._wallpapersList.next(result.wallpaperNames)
    })
  }
}

interface ChampionAlias {
  championId: string
  alias: string
}
