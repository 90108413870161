import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Player} from "../../components/picker/picker.component";
import {Role} from "../../Utils/RolesUtil";
import {IPickerGetPredictionsResult, PickerGetPredictionsParam} from "./pickerApiTypes";

@Injectable()
export class PickerApiService {

  private readonly base_api_url = 'http://localhost:5000/api/picker/'

  constructor(private http: HttpClient) {
  }

  public getPredictions(allies: Player[], enemies: Player[], role?: Role) {
    const pickerPredictionParam = new PickerGetPredictionsParam(allies, enemies, role);
    const url = this.base_api_url + 'getPrediction';

    return this.http.post<IPickerGetPredictionsResult>(url, pickerPredictionParam);
  }


}
