import {Component, Input, OnInit} from '@angular/core';
import {SummonerApiService} from '../../../services/summoner/summonerApi.service';
import {GetSummonerDataResult} from '../../../services/summoner/summonerApiTypes';
import {Router} from '@angular/router';
import {GameDataService} from "../../../services/game-data/game-data.service";
import {Region} from "../../../services/game-data/game-data-api-types";

@Component({
  selector: 'app-summoner-alternatives',
  templateUrl: './summoner-alternatives.component.html',
  styleUrls: ['./summoner-alternatives.component.less'],
  providers: [GameDataService, SummonerApiService]
})
export class SummonerAlternativesComponent implements OnInit {

  @Input() name = '';
  public loadingCount = 0;
  public available: RegionAlternative[] = [];
  public unavailable: RegionAlternative[] = [];

  constructor(
    private gameDataService: GameDataService,
    private summonerApiService: SummonerApiService,
    private router: Router
  ) {
    // noop
  }

  ngOnInit(): void {
    this.gameDataService.regions.subscribe((regions: Array<Region>) => {
      this.loadingCount = regions.length;
      regions.forEach((region: Region) => {
        this.getSummonerForRegion(region);
      });
    });
  }

  private getSummonerForRegion(region: Region): void {
    this.summonerApiService.getSummonerAccountData(this.name, region.apiKey)
      .subscribe({
        next: (accountData: GetSummonerDataResult) => {
          this.available.push(new RegionAlternative(region, accountData));
          this.loadingCount--;
        },
        error: () => {
          this.unavailable.push(new RegionAlternative(region, null));
          this.loadingCount--;
        }
      });
  }

  onRegionClick(alternative: RegionAlternative): void {
    this.router.navigate(
      ['summoner', alternative.region.apiKey, this.name],
      {state: {summonerAccountData: alternative.accountData}}
    );
  }
}

class RegionAlternative {
  public region: Region;
  public accountData: GetSummonerDataResult | null;

  constructor(
    region: Region,
    accountData: GetSummonerDataResult | null
  ) {
    this.region = region;
    this.accountData = accountData;
  }
}
