import {Injectable} from "@angular/core";
import {TierListApiService} from "./tierListApi.service";
import {BehaviorSubject, Observable} from "rxjs";
import {ChampionsStats} from "./championStatsTypes";

@Injectable()
export class TierListService {
  private _championsStats: BehaviorSubject<any> = new BehaviorSubject(null);
  public readonly championsStats: Observable<ChampionsStats> = this._championsStats.asObservable()

  constructor(private tierListApiService: TierListApiService) {
    this.getAllChampionsStats()
  }


  public getAllChampionsStats() {
    return this.tierListApiService.getAllChampionsStats().subscribe((result: ChampionsStats) => {
      this._championsStats.next(result)
    })
  }
}

