import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SummonerRoutingModule} from './summoner-routing.module';
import { SummonerRankComponent } from './summoner-overview/summoner-rank/summoner-rank.component';
import {SummonerOverviewComponent} from './summoner-overview/summoner-overview.component';
import {MaterialModule} from '../../material/material.module';
import { SummonerAlternativesComponent } from './summoner-alternatives/summoner-alternatives.component';
import {UtilsModule} from '../utils/utils.module';
import { SummonerMatchHistoryComponent } from './summoner-match-history/summoner-match-history.component';

@NgModule({
  declarations: [
    SummonerRankComponent,
    SummonerOverviewComponent,
    SummonerAlternativesComponent,
    SummonerMatchHistoryComponent
  ],
  exports: [
    SummonerRankComponent,
    SummonerOverviewComponent,
    SummonerAlternativesComponent
  ],
  imports: [
    CommonModule,
    SummonerRoutingModule,
    MaterialModule,
    UtilsModule
  ]
})
export class SummonerModule { }
