import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChampionPageComponent } from './champion-page/champion-page.component';
import {UtilsModule} from "../utils/utils.module";
import {MaterialModule} from "../../material/material.module";



@NgModule({
  declarations: [ChampionPageComponent],
  imports: [
    CommonModule,
    UtilsModule,
    MaterialModule
  ]
})

export class ChampionModule { }
