import {IParticlesProps} from 'ng-particles/lib/ng-particles.module'
import { ClickMode, HoverMode, InteractivityDetect, MoveDirection, OutMode } from 'tsparticles-engine'


export class ParticlesUtil {

  public static get goldenNetworkOptions(): IParticlesProps {
    return {
      fpsLimit: 60,
      interactivity: {
        detectsOn: 'window',
        events: {
          onClick: {
            enable: true,
            mode: ClickMode.push
          },
          onHover: {
            enable: true,
            mode: HoverMode.grab
          },
          resize: true
        },
        modes: {
          bubble: {
            distance: 400,
            duration: 2,
            opacity: 0.8,
            size: 40
          },
          push: {
            quantity: 4
          },
          repulse: {
            distance: 200,
            duration: 0.4
          }
        }

      },
      particles: {
        color: {
          value: '#F1BA2D'
        },
        links: {
          color: '#F1BA2D',
          distance: 150,
          enable: true,
          opacity: 0.5,
          width: 1
        },
        collisions: {
          enable: true
        },
        move: {
          direction: MoveDirection.none,
          enable: true,
          outMode: OutMode.bounce,
          random: false,
          speed: 1,
          straight: false
        },
        number: {
          density: {
            enable: true,
            value_area: 120
          },
          value: 15
        },
        opacity: {
          value: 1
        },
        shape: {
          type: 'circle'
        },
        size: {
          random: true,
          value: 1
        }

      },
      detectRetina: true
    }
  }

  public static getBackgroundBubblesOptions(wallpaperName: string = 'arcana_camille'): IParticlesProps {
    return {
      background: {
        image: `url("/assets/images/champions_backgrounds/${wallpaperName}.jpg")`
      },
      backgroundMask: {
        composite : 'destination-out',
        enable: true,
        cover: {
          color: '#090D31',
          opacity: 0.95
        }
      },
      fpsLimit: 60,
      interactivity: {
        detectsOn: InteractivityDetect.window,
        events: {
          onClick: {
            enable: true,
            mode: ClickMode.push
          },
          onHover: {
            enable: true,
            mode: HoverMode.bubble
          },
          resize: true
        },
        modes: {
          bubble: {
            distance: 500,
            duration: 2,
            opacity: 0.9,
            size: 200
          },
          push: {
            quantity: 4
          },
          repulse: {
            distance: 200,
            duration: 0.4
          }
        }

      },
      particles: {
        color: {
          value: '#FFF'
        },
        links: {
          color: '#FFF',
          distance: 120,
          enable: true,
          opacity: 1,
          width: 1,
          triangles: {
            enable: true
          }
        },
        collisions: {
          enable: false
        },
        move: {
          direction: MoveDirection.none,
          enable: true,
          outMode: OutMode.bounce,
          random: false,
          speed: 2,
          straight: false
        },
        number: {
          density: {
            enable: true,
            value_area: 180
          },
          value: 20
        },
        opacity: {
          value: 1
        },
        shape: {
          type: 'circle'
        },
        size: {
          random: {
            enable: true,
            minimumValue: 2
          },
          value: 20,
        }

      },
      detectRetina: true,
      pauseOnBlur: true
    }
  }

  public static getLightBackgroundBubblesOptions(wallpaperName?: string): IParticlesProps {
    const defaultOptions = ParticlesUtil.getBackgroundBubblesOptions(wallpaperName)
    const links = defaultOptions?.particles?.links as any
    if (links?.enable) { links.enable = false }
    if (defaultOptions.particles?.size?.value) {
      defaultOptions.particles.size.value = 10
    }
    return defaultOptions
  }

  public static getRandomWallpaperName(wallpaperNames: string[]): string {
    return wallpaperNames[Math.floor(Math.random() * wallpaperNames.length)]
  }
}
