<div class="player-container"
     [ngClass]="{'selected-player' : isPlayerSelected(),
     'selecting-role' : showRoleSelection(),
     'user-role' : showRoleImage()}">
  <img *ngIf="showChampionImage()" [src]="getChampionUrl()" (click)="onClickPlayer()" class="champion-round"/>
  <div *ngIf="showPlaceholderImage()" (click)="onClickPlaceholder()" class="role-container placeholder-container">
    <mat-icon svgIcon="champ-placeholder"></mat-icon>
  </div>
  <div *ngIf="showRoleImage()" class="role-container">
    <mat-icon svgIcon="{{getRoleGlyph(selectedRole)}}"></mat-icon>
  </div>
  <div *ngIf="showDeleteButton()" class="delete-btn-wrapper btn-hover-effect">
    <i class="material-icons">delete_outline</i>
  </div>
</div>

<div *ngIf="showRoleSelection()" class="role-picker">
  <div *ngFor="let role of selectableRoles" class="role-wrapper">
    <mat-icon svgIcon="{{getRoleGlyph(role)}}" (click)="onPickRole(role)"></mat-icon>
  </div>
</div>
<div *ngIf="showRoleSelection()" class="dark-veil" (click)="onClickPlaceholder()">
</div>

