<app-loader *ngIf="isSummonerLoading"></app-loader>
<div *ngIf="!isSummonerLoading" class="summoner-overview-container">
  <div class="summoner-header">
    <div class="background-container" [ngStyle]="backgroundImageStyle">
    </div>
    <div class="summoner-info">
      <div class="summoner-avatar-container">
        <img class="summoner-avatar" [src]="summoner?.imageUrl" alt="Summoner image">
        <span class="summoner-level">{{summoner?.level}}</span>
      </div>
      <div class="summoner-info-container">
        <span class="summoner-name">{{summoner?.name}}</span>
      </div>
    </div>
    <app-summoner-rank *ngIf="summoner?.rankedSoloData" [rankData]="summoner?.rankedSoloData"
      class="summoner-ranked-container">
    </app-summoner-rank>
    <app-summoner-rank *ngIf="summoner?.rankedFlexData" [rankData]="summoner?.rankedFlexData"
      class="summoner-ranked-container">
    </app-summoner-rank>
  </div>
  <mat-tab-group>
    <mat-tab label="Match History">
      <ng-template matTabContent>
        <div class="widgets_container">
          <div class="widget-placeholder"></div>
          <div class="widget-placeholder"></div>
        </div>
        <div *ngIf="!isHistoryLoading" class="match-history" (scroll)="onHistoryScroll($event)">
          <app-summoner-match-history [games]="games" [summoner]="summoner"></app-summoner-match-history>
          <app-loader *ngIf="isLoadingMoreGames"></app-loader>
        </div>
        <app-loader *ngIf="isHistoryLoading"></app-loader>
      </ng-template>
    </mat-tab>
    <mat-tab label="Second">
      <ng-template matTabContent>
      </ng-template>
    </mat-tab>
    <mat-tab label="Third">
      <ng-template matTabContent>
      </ng-template>
    </mat-tab>
  </mat-tab-group>

</div>
