<mat-toolbar class="nav-bar">
  <mat-toolbar-row>
    <div class="left item-wrapper" routerLink="/">
      <div class="logo-wrapper">
        <b>Elo</b>dvisor
      </div>
    </div>

    <div class="nav-buttons-wrapper">
      <div class="nav-btn btn-hover-effect" routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
        <i class="material-icons">home</i>
      </div>
      <div class="nav-btn btn-hover-effect" routerLink="/picker" routerLinkActive="active">
        <i class="material-icons">emoji_objects</i>
        <div class="beta-tag">ALPHA</div>
      </div>
      <div class="nav-btn btn-hover-effect" routerLink="/tier-list" routerLinkActive="active">
        <i class="material-icons">trending_up</i>
      </div>
    </div>

    <div class="right item-wrapper">
      <div class="search-bar-container">
        <app-search-bar fxHide.lt-md *ngIf="showSearchBar" class="nav-search-bar" searchBarType="toolbar"></app-search-bar>
        <button mat-icon-button fxHide.gt-sm (click)="onMobileSearch()"><mat-icon>search</mat-icon></button>
      </div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
