import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.less']
})
export class LoaderComponent implements OnInit {

  @Input() loaderType = 'normal';

  constructor() {
    // noop
  }

  ngOnInit(): void {
    // noop
  }

  getClass(): string {
    return `lds-ring ${this.loaderType}`;
  }
}
