import {Role} from "../../Utils/RolesUtil";
import {Player} from "../../components/picker/picker.component";
import {Champion} from "../game-data/game-data-api-types";

export class PickerGetPredictionsParam {
  public alliesNames: string[];
  public enemiesNames: string[];
  public role?: string;

  constructor(allies: Player[], enemies: Player[], role?: Role) {
    this.alliesNames = allies.map(ally => PickerGetPredictionsParam.getChampionString(ally, role));
    this.enemiesNames = enemies.map(enemy => PickerGetPredictionsParam.getChampionString(enemy, role));
    if (role) {
      this.role = role
    }
  }

  private static getChampionString(player: Player, role?: Role) {
    if (player.isUser) {
      return role ?? "UNK"
    }
    return player.pickedChampion?.name || 'UNK';
  }
}


export interface IPickerGetPredictionsResult {
  predictions: IPredictionResult[];
}


export interface IPredictionResult {
  name: string;
  score: number;
}

export class Prediction {
  public champion: Champion;
  public score: number

  constructor(champion: Champion, score: number) {
    this.champion = champion;
    this.score = +score.toFixed(2);
  }
}
