import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {GameDataService} from "../../services/game-data/game-data.service";
import {Champion} from "../../services/game-data/game-data-api-types";
import {ISelectRoleParameter} from "../picker-player-button/picker-player-button.component";
import {PickerApiService} from "../../services/picker/pickerApi.service";
import {Role} from "../../Utils/RolesUtil";
import {IPickerGetPredictionsResult, IPredictionResult, Prediction} from "../../services/picker/pickerApiTypes";

@Component({
  selector: 'app-picker',
  templateUrl: './picker.component.html',
  styleUrls: ['./picker.component.less'],
  providers: [PickerApiService]
})
export class PickerComponent implements OnInit {
  @ViewChild('championSearchBar') private inputEl ?: ElementRef
  public allChampions: Champion[] = []
  public availableChampions: Champion[] = []
  public filteredChampions: Champion[] = []
  public searchedText: string = ''
  public allies: Player[] = [new Player(1), new Player(4), new Player(5), new Player(8), new Player(9)];
  public enemies: Player[] = [new Player(2), new Player(3), new Player(6), new Player(7), new Player(10)];
  public currentPhase : PickerPhase;
  public selectedPlayer: Player;
  public userRole?: Role;
  public predictions: Prediction[] = [];

  private get userPlayer() {
    const allPlayers = [];
    allPlayers.push(...this.allies);
    allPlayers.push(...this.enemies);
    return allPlayers.find(player => player.isUser)
  }


  private get alreadyPickedChampions() {
    const pickedChampions: Champion[] = [];
    this.allies.forEach(ally => {
      if (ally.pickedChampion) {
        pickedChampions.push(ally.pickedChampion)
      }
    })
    this.enemies.forEach(enemy => {
      if (enemy.pickedChampion) {
        pickedChampions.push(enemy.pickedChampion)
      }
    })
    return pickedChampions
  }


  constructor(private gameDataService : GameDataService,
              private pickerApiService : PickerApiService) {
    this.currentPhase = PickerPhase.RoleSelection;
    this.selectedPlayer = this.allies[0];
  }

  ngOnInit(): void {
    this.gameDataService.championsList.subscribe((champions) => {
      this.allChampions = champions;
      this.availableChampions = champions;
      this.filteredChampions = champions;
    })
  }

  getChampionUrl(champion: Champion) {
    return this.gameDataService.getChampionUrl(champion.id)
  }

  public onSearchChange() {
    this.filteredChampions = this.availableChampions.filter(champ => {
      const name = champ.name.toLowerCase();
      const searchedText = this.searchedText.toLowerCase();
      let isChampMatchingSearch = true;
      let index = -1;
      // TODO : finir ça proprement
      searchedText.split('').forEach(letter => {
        if (name.indexOf(letter) <= index) {
          isChampMatchingSearch = false
        }
        else {
          index = name.indexOf(letter)
        }
      })
      return name.includes(searchedText);
    })
  }

  public isChampionSelectionPhase() {
    return this.currentPhase == PickerPhase.ChampionSelection;
  }

  public isRoleSelectionPhase() {
    return this.currentPhase == PickerPhase.RoleSelection;
  }

  public isResultsPhase() {
    return this.currentPhase == PickerPhase.Results;
  }

  public onPickChampion(champion : Champion) {
    this.selectedPlayer.pickedChampion = champion;

    // Select automatically next player to pick
    let nextPlayerIndex = this.selectedPlayer.index + 1;
    if (this.userPlayer?.index == nextPlayerIndex) {nextPlayerIndex ++}
    const nextPlayer = this.allies.find(ally => ally.index == nextPlayerIndex) ||
      this.enemies.find(ally => ally.index == nextPlayerIndex);
    if (nextPlayer) {
      this.onClickPlayer(nextPlayer)
    }

    // Filter available champions with champions already picked
    this.availableChampions = this.allChampions.filter(champion => !this.alreadyPickedChampions.includes(champion));
    this.resetSearchedText()
  }


  public isPredictButtonAvailable() {
    return this.alreadyPickedChampions.length > 0 && !this.isResultsPhase();
  }

  public onPredict() {
    this.currentPhase = PickerPhase.Results;
    this.pickerApiService.getPredictions(this.allies, this.enemies, this.userRole).subscribe((result : IPickerGetPredictionsResult) => {
      this.predictions = [];
      result.predictions.forEach((pred: IPredictionResult) => {
        const champion = this.allChampions.find(champ => champ.name == pred.name)
        if (!champion) { return }
        const newPred = new Prediction(champion, pred.score)
        this.predictions.push(newPred);
      })
    })
  }

  public trackByChampId(index:number, champion : Champion) {
    return champion.key;
  }

  private resetSearchedText() {
    this.filteredChampions = this.availableChampions;
    this.searchedText = '';
  }


  getHelpLbl() {
    switch (this.currentPhase) {
      case PickerPhase.RoleSelection:
        return "Click on your pick position to select a role"
      case PickerPhase.ChampionSelection:
        return "Select ally and enemy champions to help our AI to give you the best pick"
      case PickerPhase.Results:
        return "Our baby AI advises you these picks !"
      default:
        return ""
    }
  }

  public onClickPlayer(player: Player) {
    if (this.currentPhase == PickerPhase.RoleSelection) {
      player.isSelectingRole = true;
    }
    else if (this.currentPhase == PickerPhase.ChampionSelection){
      this.selectedPlayer.isCurrentlySelected = false;
      player.isCurrentlySelected = true;
      this.selectedPlayer = player;
    }
    else if (this.currentPhase == PickerPhase.Results) {
      this.currentPhase = PickerPhase.ChampionSelection;
      this.onClickPlayer(player);
    }
  }

  public onSelectRole(selectRoleParam: ISelectRoleParameter) {
    const player = selectRoleParam.player;
    this.userRole = selectRoleParam.role;
    player!.isUser = true;
    player!.isSelectingRole = false;
    this.currentPhase = PickerPhase.ChampionSelection;
    this.inputEl?.nativeElement.focus();
    this.onClickPlayer(this.allies[0]);
  }

}

export enum PickerPhase {
  RoleSelection,
  ChampionSelection,
  Results
}

export class Player {
  index: number;
  isUser : boolean = false;
  isCurrentlySelected: boolean = false;
  isSelectingRole: boolean = false;
  pickedChampion?: Champion;

  constructor(index: number, isSelected = false) {
    this.index = index;
    this.isCurrentlySelected = isSelected
  }
}
