import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {Champion, ChampionSpell} from "../../../services/game-data/game-data-api-types";
import {GameDataService} from "../../../services/game-data/game-data.service";
import {ChampionUtil} from "../../../Utils/ChampionUtil";

@Component({
  selector: 'app-champion-page',
  templateUrl: './champion-page.component.html',
  styleUrls: ['./champion-page.component.less']
})
export class ChampionPageComponent implements OnInit {

  public championData?: Champion

  constructor(private route: ActivatedRoute,
              private gameDataService: GameDataService) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const championId = params.get('championId') as string;
      this.gameDataService.championsList.subscribe(champions => {
        this.championData = champions.find(champion => champion.id == championId)
      })
    })
  }

  public getChampionImage() {
    return this.gameDataService.getChampionUrl(this.championData?.id ?? "")
  }

  public getPassiveUrl(spell: ChampionSpell) {
    return this.gameDataService.getChampionPassiveUrl(this.championData?.id ?? "")
  }

  public getSpellUrl(spell: ChampionSpell) {
    return this.gameDataService.getChampionSpellUrl(spell.image.full)
  }
}
