import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from "@angular/router";
import {HomePageComponent} from "../home-page/home-page.component";
import {PickerComponent} from "../picker/picker.component";
import {MalDashboardComponent} from "../admin/mal-dashboard/mal-dashboard.component";
import {PageNotFoundComponent} from "../page-not-found/page-not-found.component";


const routes: Routes = [

];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class SummonerRoutingModule { }
