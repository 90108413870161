<div class="login-form" *ngIf="!isUserAuthorized">
  <mat-form-field appearance="outline">
    <mat-label>Enter your password</mat-label>
    <input matInput [type]="hide ? 'password' : 'text'" [(ngModel)]="currentCode">

    <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
      <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
    </button>
  </mat-form-field>
  <button mat-raised-button color="primary" (click)="onTypePassword()">Login</button>
</div>


<div *ngIf="isUserAuthorized" class="admin-dashboard-container">
  <button mat-raised-button color="primary" (click)="onFetchSummoners()">Fetch Summoners</button>
  <button mat-raised-button color="primary" (click)="onFetchHistories()">Fetch Histories</button>
  <button mat-raised-button color="primary" (click)="onFetchGamesData()">Fetch Games Data</button>
</div>
