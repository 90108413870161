import {BrowserModule} from '@angular/platform-browser'
import {NgModule} from '@angular/core'

import {AppRoutingModule} from './app-routing.module'
import {HomePageComponent} from './components/home-page/home-page.component'
import {NavigationBarComponent} from './components/navigation-bar/navigation-bar.component'
import {PickerComponent} from './components/picker/picker.component'
import {MalDashboardComponent} from './components/admin/mal-dashboard/mal-dashboard.component'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {NgParticlesModule} from 'ng-particles'
import {FormsModule, ReactiveFormsModule} from '@angular/forms'
import {PageNotFoundComponent} from './components/page-not-found/page-not-found.component'
import {HttpClientModule} from '@angular/common/http'
import {SummonerModule} from './components/summoner/summoner.module'
import {AppComponent} from './app-component/app.component'
import {PickerPlayerButtonComponent} from './components/picker-player-button/picker-player-button.component'
import {GameDataService} from './services/game-data/game-data.service'
import {GameDataApiService} from './services/game-data/game-data-api.service'
import {DragDropModule} from '@angular/cdk/drag-drop'
import {MaterialModule} from './material/material.module'
import {HashLocationStrategy, LocationStrategy} from '@angular/common'
import {SummonerComponent} from './components/summoner/summoner.component'
import {UtilsModule} from './components/utils/utils.module'
import { SearchBarComponent } from './components/search-bar/search-bar.component'
import { TierListComponent } from './components/tier-list/tier-list.component'
import {TierListApiService} from './services/tier-list/tierListApi.service'
import {ChampionModule} from './components/champion/champion.module'

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    NavigationBarComponent,
    PickerComponent,
    MalDashboardComponent,
    PageNotFoundComponent,
    SummonerComponent,
    PickerPlayerButtonComponent,
    SearchBarComponent,
    TierListComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    SummonerModule,
    ChampionModule,
    BrowserAnimationsModule,
    NgParticlesModule,
    ReactiveFormsModule,
    FormsModule,
    DragDropModule,
    MaterialModule,
    UtilsModule,
  ],
  providers: [
    GameDataService,
    GameDataApiService,
    TierListApiService,
    {provide: LocationStrategy, useClass: HashLocationStrategy}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
