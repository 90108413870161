<div class="summoner-container">
  <app-loader *ngIf="uiState == uiStates.Loading"></app-loader>
  <app-summoner-overview
    *ngIf="uiState == uiStates.Content && accountData"
    [region]="region"
    [accountData]="accountData">
  </app-summoner-overview>
  <app-summoner-alternatives
    *ngIf="uiState == uiStates.Alternatives"
    [name]="name">
  </app-summoner-alternatives>
</div>
