
<div *ngIf="championData" class="champion-page">
  <div class="champion-header">
    <img [src]="getChampionImage()" class="champion-img">
    <span class="champion-name"> {{championData?.name}}</span>
  </div>

  <mat-tab-group>
    <mat-tab label="Build">
      <ng-template matTabContent>
        <div class="spells-container">
          <div *ngFor="let spell of championData.spells" class="spell-wrapper">
            <img [src]="getSpellUrl(spell)">
          </div>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab label="Counters">
      <ng-template matTabContent>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>

<app-loader *ngIf="!championData"></app-loader>

