import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Player} from "../picker/picker.component";
import {Role, RolesUtil} from "../../Utils/RolesUtil";
import {GameDataService} from "../../services/game-data/game-data.service";

@Component({
  selector: 'app-picker-player-button',
  templateUrl: './picker-player-button.component.html',
  styleUrls: ['./picker-player-button.component.less']
})
export class PickerPlayerButtonComponent implements OnInit {
  @Input() player!: Player;
  @Output() playerChange = new EventEmitter<Player>();
  @Output() onSelectRole = new EventEmitter<ISelectRoleParameter>();
  @Output() onSelectPlayer = new EventEmitter<Player>();

  public selectedRole?: Role;
  public selectableRoles = [Role.Top, Role.Jungle, Role.Mid, Role.Adc, Role.Support];

  constructor(private gameDataService: GameDataService) {}

  ngOnInit(): void {
  }

  public getChampionUrl() {
    const pickedChampId = this.player?.pickedChampion?.id ?? ""
    return this.gameDataService.getChampionUrl(pickedChampId);
  }

  public isPlayerSelected() {
    return this.player?.isCurrentlySelected;
  }

  public showDeleteButton() {
    return this.showChampionImage();
  }

  public onPickRole(role: Role) {
    this.selectedRole = role;
    this.onSelectRole.next({player: this.player, role: this.selectedRole} as ISelectRoleParameter);
  }

  public showChampionImage() {
    return !this.player?.isUser && this.player?.pickedChampion;
  }

  public showPlaceholderImage() {
    return !this.player?.isUser && !this.player?.pickedChampion;
  }

  public showRoleImage() {
    return this.player?.isUser;
  }

  public showRoleSelection() {
    if (this.player.isSelectingRole) {
    }
    return this.player?.isSelectingRole;
  }

  public getRoleGlyph(role?: Role) {
    if (role == undefined) { return }
    return RolesUtil.getGlyphFromRole(role)
  }

  public onClickPlaceholder() {
    if (this.player.isSelectingRole) {
      this.player.isSelectingRole = false;
      this.playerChange.emit(this.player)
    }
    else {
      this.onClickPlayer();
    }
  }

  onClickPlayer() {
    this.onSelectPlayer.next(this.player);
  }
}

export interface ISelectRoleParameter {
  player?: Player;
  role?: Role;
}
