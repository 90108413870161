import {Component, Input, OnInit} from '@angular/core';
import {SummonerQueue} from '../../../../services/summoner/summonerTypes';

@Component({
  selector: 'app-summoner-rank',
  templateUrl: './summoner-rank.component.html',
  styleUrls: ['./summoner-rank.component.less']
})
export class SummonerRankComponent implements OnInit {

  @Input() rankData?: SummonerQueue;

  constructor() { }

  ngOnInit(): void {
  }

}
